import React, { useEffect, useState } from 'react'
import axiosInstance from '../../utils/axios/axiosConfig';
import { useAuth } from '../../utils/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ForgetPassChange } from '../../components';
import axios from 'axios';
import { Eye, EyeOff, AlertCircle } from 'lucide-react';
import photo from '../../assets/images/photoLogin.jpg'
function Login() {
  // Keeping all the existing state and handlers unchanged
  const [passwordToggle, setPasswordToggle] = useState(false);
  const { login } = useAuth();
  const [loginData, setLoginData] = useState({ email: null, password: null, code: null })
  const [loading, setLoding] = useState(false)
  const [codeVerify, setCodeVerify] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [forgetPassword, setForgetPaasword] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  // Keeping all the existing handlers and effects unchanged
  const apiSanctum = process.env.REACT_APP_BACKEND_URL_SANCTUM
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);

  // All existing handlers remain the same
  const handleInput = (e, index) => {
    const value = e.target.value;
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
    setLoginData({ ...loginData, code: newVerificationCode.join('') })
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (paste.length === 6 && /^[0-9]*$/.test(paste)) {
      const newVerificationCode = paste.split('');
      setVerificationCode(newVerificationCode);
      setLoginData({ ...loginData, code: paste });
    }
    e.preventDefault();
  };

  useEffect(() => {
    navigate('/Login')
    axios.get(`${apiSanctum}/csrf-cookie`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
  }, [])

  const navigate = useNavigate()

  // Keeping all the existing handlers unchanged
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.email || !loginData.password) {
      setErrorMessage('Both email and password are required.');
      return;
    }
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/login', loginData);
      const responseData = response.data

      if (responseData && responseData.success) {
        setCodeVerify(true)
      }
      else if (responseData && responseData.user) {
        const { token } = responseData;
        login(token)
        setRedirectLoading(true);
        setTimeout(() => {
          setRedirectLoading(false);
          navigate('/projects');
        }, 2000);
      }
      setLoding(false)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
    }
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    if (!loginData.code) {
      setErrorMessage('The code is required.');
      return;
    }
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/code-verification', loginData);
      const responseData = response.data
      if (responseData && responseData.user) {
        const { token } = responseData;
        setCodeVerify(false)
        login(token)
        setRedirectLoading(true);
        setTimeout(() => {
          setRedirectLoading(false);
          navigate('/projects');
        }, 2000);
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
    }
  };

  const handleFrogetPassword = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/forget-passKey', loginData);
      const responseData = response.data
      if (responseData && responseData.data && responseData.data.success) {
        setCodeVerify(true)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
    }
  };

  const handleForgetPassCodeVerification = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/forgetPass-verification', loginData);
      const responseData = response.data
      if (responseData && responseData.data && responseData.data.success) {
        setCodeVerify(false)
        setChangePasswordModal(true)
        setForgetPaasword(false)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
    }
  };

  return (
    <main className="flex min-h-screen">
      {redirectLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-neutral-900">
          <div className="animate-spin w-6 h-6 border-4 border-neutral-800 dark:border-neutral-100 dark:border-t-transparent border-t-transparent rounded-full" />
        </div>
      ) : (
        <>
          {/* Left side with pattern background */}
          <div className="hidden lg:flex w-1/2 bg-blue-500 items-center justify-center relative overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${photo})`,
                backgroundSize: 'cover', // Makes the image cover the container
                backgroundPosition: 'center'
              }}
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50" />
            <div className="relative z-10 text-center">
              <h1 className="text-white text-6xl font-bold tracking-wider">SOLADZ</h1>
            </div>
          </div>


          {/* Right side with form */}
          <div className="flex-1 flex items-center justify-center p-4 bg-white dark:bg-neutral-900">
            <div className="w-full max-w-md space-y-8 px-4">
              {!forgetPassword ? (
                <div className="space-y-6">
                  <div className="text-center">
                    <h2 className="text-4xl font-bold text-neutral-800 dark:text-neutral-100">
                      <span className="text-blue-500">Welcome</span> Back!
                    </h2>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">Login to get started</p>
                  </div>

                  {errorMessage && (
                    <div className="p-4 rounded-lg bg-red-50 border border-red-200 dark:bg-red-900/30 dark:border-red-800">
                      <div className="flex items-center gap-2 text-red-700 dark:text-red-400">
                        <AlertCircle className="h-5 w-5" />
                        <p className="text-sm font-medium">{errorMessage}</p>
                      </div>
                    </div>
                  )}

                  <form onSubmit={handleLogin} className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-start text-sm font-medium text-gray-700 dark:text-gray-300">
                        Email address
                      </label>
                      <input
                        id="email"
                        type="email"
                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                        className="mt-1 block w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:text-gray-100"
                        placeholder="Enter your email"
                      />
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm text-start font-medium text-gray-700 dark:text-gray-300">
                        Password
                      </label>
                      <div className="relative">
                        <input
                          id="password"
                          type={passwordToggle ? 'text' : 'password'}
                          onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                          className="mt-1 block w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:text-gray-100"
                          placeholder="Enter your password"
                        />
                        <button
                          type="button"
                          onClick={() => setPasswordToggle(!passwordToggle)}
                          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400"
                        >
                          {passwordToggle ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                        </button>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full py-3 px-4 rounded-lg bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                    >
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
                        </div>
                      ) : (
                        'Sign in'
                      )}
                    </button>
                  </form>

                  <div className="text-center">
                    <button
                      onClick={() => setForgetPaasword(true)}
                      className="text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400"
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="text-center">
                    <h2 className="text-4xl font-bold text-neutral-800 dark:text-neutral-100">Reset Password</h2>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                      Enter your email to reset your password
                    </p>
                  </div>

                  {errorMessage && (
                    <div className="p-4 rounded-lg bg-red-50 border border-red-200 dark:bg-red-900/30 dark:border-red-800">
                      <div className="flex items-center gap-2 text-red-700 dark:text-red-400">
                        <AlertCircle className="h-5 w-5" />
                        <p className="text-sm font-medium">{errorMessage}</p>
                      </div>
                    </div>
                  )}

                  <form onSubmit={handleFrogetPassword} className="space-y-6">
                    <div>
                      <label htmlFor="reset-email" className="block text-start text-sm font-medium text-gray-700 dark:text-gray-300">
                        Email address
                      </label>
                      <input
                        id="reset-email"
                        type="email"
                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                        className="mt-1 block w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:text-gray-100"
                        placeholder="Enter your email"
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full py-3 px-4 rounded-lg bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                    >
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
                        </div>
                      ) : (
                        'Reset Password'
                      )}
                    </button>
                  </form>

                  <div className="text-center">
                    <button
                      onClick={() => setForgetPaasword(false)}
                      className="text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400"
                    >
                      Back to Sign In
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* Verification Code Modal */}
      {codeVerify && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-neutral-800 rounded-2xl p-8 max-w-md w-full">
            <h3 className="text-2xl font-bold mb-4 text-neutral-800 dark:text-neutral-100">Verify Code</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Enter the verification code sent to your email
            </p>

            {errorMessage && (
              <div className="mb-6 p-4 rounded-lg bg-red-50 border border-red-200 dark:bg-red-900/30 dark:border-red-800">
                <div className="flex items-center gap-2 text-red-700 dark:text-red-400">
                  <AlertCircle className="h-5 w-5" />
                  <p className="text-sm font-medium">{errorMessage}</p>
                </div>
              </div>
            )}

            <form onSubmit={!forgetPassword ? handleCodeVerification : handleForgetPassCodeVerification}>
              <div className="grid grid-cols-6 gap-2 mb-6">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={verificationCode[index]}
                    onChange={(e) => handleInput(e, index)}
                    onPaste={handlePaste}
                    className="w-full h-12 text-center text-lg font-semibold rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white"
                  />
                ))}
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full py-3 px-4 rounded-lg bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
                  </div>
                ) : (
                  'Verify Code'
                )}
              </button>
            </form>
          </div>
        </div>
      )}

      {changePasswordModal && (
        <ForgetPassChange
          email={loginData.email}
          setChangePasswordModal={setChangePasswordModal}
          code={loginData.code}
        />
      )}
    </main>
  );
}

export default Login;

