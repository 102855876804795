import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Select({ options, label, handleChange, name, defaultV, disabled = false }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultV);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelectedValue(defaultV)
  }, [defaultV])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    handleChange({ target: { name, value } });
    setIsOpen(false);
  };

  const getDisplayValue = () => {
    if (selectedValue === null) return label;
    const selected = options.find(item => item.id === selectedValue );
    return selected ? t(selected.name || selected.fullName || selected.title || selected.type || '') : label;
  };

  return (
    <div ref={selectRef} className="relative w-full">
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={`relative w-full h-12 px-4 py-3 border-neutral-200 dark:border-neutral-700 text-left bg-neutral-50 dark:bg-neutral-900 border rounded-lg shadow-sm ${
          disabled
            ? 'cursor-not-allowed opacity-50'
            : ' focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-300 dark:focus:border-blue-300'
        }  text-gray-900 dark:text-gray-100`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={disabled}
      >
        <span className="block truncate">{getDisplayValue()}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg className="w-5 h-5 text-gray-400 dark:text-gray-300" viewBox="0 0 20 20" fill="none" stroke="currentColor">
            <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </button>

      {isOpen && (
        <ul
          className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 focus:outline-none sm:text-sm"
          tabIndex={-1}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-0"
        >
          {options.map((item, index) => (
            <li
              key={item.id}
              className={`cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-100 dark:hover:bg-neutral-900 ${
                selectedValue === item.id ? 'bg-blue-200 dark:bg-neutral-900' : ''
              } text-gray-900 dark:text-gray-100`}
              id={`listbox-option-${index}`}
              role="option"
              aria-selected={selectedValue === item.id}
              onClick={() => handleSelectChange(item.id)}
            >
              <span className={`block text-start truncate ${selectedValue === item.id ? 'font-semibold' : 'font-normal'}`}>
                {t(item.name || item.fullName || item.title || item.type || '')}
              </span>
              {selectedValue === item.id && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 dark:text-blue-400">
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Select;
