import React, { useEffect, useState } from 'react'
import { DeleteModal, Loading, Input, Select, MultiSelect } from '../../components';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import avatar from '../../assets/images/avatar.jpg'
import { motion, AnimatePresence } from 'framer-motion';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
function Users() {
  const { currentUser } = useCurrentUser();

  const [loading, setLoding] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [users, setUsers] = useState([])
  const Etats = [{ name: 'active', id: 'active' }, { name: 'inactive', id: 'inactive' }]

  const [formData, setFormData] = useState({ double_auth: false, email: null, fullName: null, phone: null, etat: null, password: null, projects: [] })



  const [userId, setUserId] = useState(null)
  const [done, setDone] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [userModal, setUserModal] = useState(false)
  const [ProjectsList, setProjectsList] = useState([])


  useEffect(() => {
    getUsers()
    getProject()
  }, [done])


  const InitialiseUser = () => {
    setFormData({ double_auth: false, email: null, fullName: null, phone: null, etat: null, password: null, projects: [] })
  }

  const getProject = async () => {
    try {
      setLoding(true)
      const response = await axiosInstance.get('/projects/search');
      const responseData = response.data

      if (responseData) {
        setProjectsList(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const getUsers = async () => {
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.get('/users/search');
      const responseData = response.data

      if (responseData) {
        setErrorMessage('')
        setUsers(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      toast.error(error.response.data.message)
      setLoding(false)
      console.error('user creation failed:', error.response.data);
    }
  };

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };




  const getUserToUpdate = async (id) => {
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.get(`/users/get/${id}`);
      const responseData = response.data
      if (responseData) {
        setErrorMessage('')
        setFormData(responseData.data)

      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
    }
  }

  const ToggleModal = async (id) => {
    if (id) {
      setUserId(id)
      getUserToUpdate(id)
      setUserModal(true)
    } else {
      setUserId(null)
      setUserModal(!userModal)

    }
  }

  const save = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage('')
      setLoding(true)
      let response = null
      if (userId) {
        response = await axiosInstance.put(`/users/update/${userId}`, formData);
      } else {
        response = await axiosInstance.post('/users/create', formData);
      }
      const responseData = response.data
      if (responseData) {
        setErrorMessage('')
        if (userId) {
          toast.success(responseData.message);
        } else {
          InitialiseUser()
          toast.success(responseData.message);
          console.log(responseData.message)
        }
        setDone(!done)
        ToggleModal()
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      toast.error(error.response.data.message);
      setLoding(false)
    }
  };


  return (
    <div className="flex flex-col w-[100%] justify-start p-6  h-[100vh]">
      {loading &&
        <Loading />
      }
      {
        currentUser?.role === 'SuperAdmin' &&
        <>

          <div className="sm:flex sm:items-center mb-6 sm:justify-between">
            <div>
              <div className="flex items-center gap-x-3">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Users</h2>
                <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400">{users.length} Users</span>
              </div>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">These companies have purchased in the last 12 months.</p>
            </div>
            <div className="flex items-center mt-4 gap-x-3">
              <button onClick={(e) => ToggleModal()} className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Add User</span>
              </button>


            </div>
          </div>
          <div className='w-full py-6 grid md:grid-cols-5 gap-3  grid-cols-1'>
            {users.length > 0 ?

              users.map(user => {
                if (user?.role === 'Admin') {
                  return (
                    <div className='relative flex justify-center border items-center bg-white dark:bg-neutral-800 w-full border-neutral-200 dark:border-neutral-700 p-4 rounded-xl'>

                      <div className="absolute z-50 top-2 right-2 text-right">
                        <Menu>
                          <MenuButton className="inline-flex flex justify-center items-center  p-1 rounded w-7 h-7  items-center gap-2 dark:text-gray-200 text-gray-800 bg-neutral-100 dark:bg-neutral-900">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                            </svg>
                          </MenuButton>
                          <Transition
                            enter="transition ease-out duration-75"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <MenuItems
                              anchor="bottom end"
                              className="w-52 origin-top-right rounded-xl border border-neutral-200 p-1  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                            >
                              <MenuItem>
                                <button onClick={(e) => { ToggleModal(user.id) }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                  Edit
                                  <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                                </button>

                              </MenuItem>

                              <MenuItem>
                                <button onClick={(e) => { setUserId(user.id); setDeleteModal(true) }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>                            Delete
                                  <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 text-white/50 group-data-[focus]:inline">⌘D</kbd>
                                </button>
                              </MenuItem>

                            </MenuItems>
                          </Transition>
                        </Menu>
                      </div>


                      <div className='grid place-items-center gap-2'>
                        <div className="relative inline-block">
                          <img className="inline-block size-[62px] rounded-full" src={user.image ? user.image : avatar} alt="Image Description" />
                          <span className="absolute top-0 end-0 block size-3.5 rounded-full ring-2 ring-white bg-yellow-400 dark:ring-neutral-900" />
                        </div>
                        <div className='grid place-items-center gap-1'>
                          <h5 className="block  text-xl font-semibold leading-snug tracking-normal text-blue-neutral-900 dark:text-gray-300 ">
                            {user.fullName}
                          </h5>
                          <p className="block dark:text-gray-400  font-[300] leading-relaxed text-[14px] antialiased">
                            {user.email}
                          </p>
                          <p className="block dark:text-blue-600 bg-blue-100 text-blue-600 dark:bg-blue-600 dark:bg-opacity-10 rounded-lg px-3 py-1 mt-2 font-[600] leading-relaxed text-[15px]  antialiased">
                            {user.role}
                          </p>
                        </div>

                      </div>
                    </div>
                  )
                }

              })


              :
              (
                <div className="min-h-60 md:col-span-5 flex flex-col bg-white border w-[100%] shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
                  <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                    <svg className="size-10 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round">
                      <line x1={22} x2={2} y1={12} y2={12} />
                      <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                      <line x1={6} x2="6.01" y1={16} y2={16} />
                      <line x1={10} x2="10.01" y1={16} y2={16} />
                    </svg>
                    <p className="mt-2 text-sm text-gray-800 dark:text-gray-300">
                      No data to show
                    </p>
                  </div>
                </div>

              )
            }
          </div>




          <AnimatePresence>

            {userModal &&
              <div
                className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                <div className="absolute bg-black opacity-80 inset-0 z-0" />
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                  className="w-full md:w-[40%] py-4 relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                  <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                    <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                      <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">User</h2>
                      <a onClick={() => ToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                    </div>



                    <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>
                      <div className='flex md:col-span-2 w-full justify-start items-center text-gray-800 dark:text-gray-100 gap-2 border border-neutral-200 dark:border-neutral-700 rounded-2xl p-3'>
                        <div className='grid place-items-start gap-3 '>
                          <div className='flex w-full justify-between items-center '>
                            <div className='flex justify-center items-center  gap-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-shield-check"><path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" /><path d="m9 12 2 2 4-4" /></svg>
                              <h5 className='text-[16px] font-[600] capitalize text-start'>2FA </h5>
                            </div>
                            <label className="inline-flex items-center cursor-pointer">
                              <input type="checkbox" onChange={(e) => handleChange(e)} checked={formData.double_auth} name='double_auth' className="sr-only peer" />
                              <div className="relative w-11 h-6 bg-neutral-200 rounded-full peer dark:bg-neutral-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-neutral-600 peer-checked:bg-blue-600" />
                            </label>
                          </div>
                          <p className='text-[12px] font-[400] capitalize text-start'>Two-factor authentication via email sends a login verification code to the user's email.</p>
                        </div>
                      </div>

                      <Input type='text' disabled={false} label="Full Name" name="fullName" handleChange={handleChange} defaultV={formData.fullName || null} />

                      <Input type='text' disabled={false} label="Phone Number" name="phone" handleChange={handleChange} defaultV={formData.phone || null} />

                      <Input type='email' disabled={false} label="Email" name="email" handleChange={handleChange} defaultV={formData.email || null} />

                      {!userId &&
                        <Input type='text' disabled={false} label="Password" name="password" handleChange={handleChange} />

                      }

                      <Select label="Etat" options={Etats} name="etat" handleChange={handleChange} defaultV={formData.etat || null} />
                      <MultiSelect label="Projects" options={ProjectsList} name="projects" handleChange={handleChange} defaultV={formData.projects || null} />




                    </div>

                    <div className='md:col-span-3 w-full flex items-center justify-end '>
                      <div className="flex items-center mt-6 gap-x-3">
                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                          {
                            !loading ?
                              (
                                <>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                  <span>Save User</span>
                                </>
                              )
                              :

                              (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                <span className="sr-only">Loading...</span>
                              </div>)

                          }

                        </button>
                      </div>
                    </div>

                  </div>
                </motion.div>
              </div>
            }
          </AnimatePresence >


          <DeleteModal setDeleteId={setUserId} open={deleteModal} setOpen={setDeleteModal} DeleteId={userId} Model='users' setDone={setDone} setLoding={setLoding} done={done} />
        </>

      }

    </div>

  )
}

export default Users