import React, { useState } from 'react';
import SideBar from '../SideBare/index'
import Navbar from '../Navbar/index'


const MainLayout = ({ children }) => {
    const [open, setOpen] = useState(false)

    return (
        <div className="flex  h-screen overflow-y-auto">
            <SideBar open={open} setOpen={setOpen} />
            <div className={`flex-1  bg-neutral-100 dark:bg-neutral-900 transition-all duration-300 flex flex-col`}>
                <Navbar open={open} setOpen={setOpen} />
                <main className={`flex w-screen md:w-full overflow-x-hidden mt-[4.3rem] `}>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default MainLayout;
