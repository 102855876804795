import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/context/AuthContext'
import avatar from '../../assets/images/avatar.jpg'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import useCurrentUser from "../../utils/hooks/useCurrentUser";


function UserDropDown() {

    const { logout } = useAuth()
    const navigate = useNavigate()
  const { currentUser } = useCurrentUser();

    const handleLogOut = (e) => {
        e.preventDefault();
        logout()
        navigate('/login')
    }
    
    return (
        <div className=" relative inline-flex ">
            <Menu>
                <MenuButton className="inline-flex flex justify-center border rounded-full p-0.5 border-neutral-200 dark:border-neutral-700 items-center ">
                    <img className="inline-block size-[40px] rounded-full" src={avatar} alt="Image Description" />

                </MenuButton>
                <Transition
                    enter="transition ease-out duration-75"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <MenuItems
                        anchor="bottom end"
                        className="w-52 origin-top-right rounded-xl border border-neutral-200 p-2 z-50 mt-3  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                    >
                        <div className="px-4 pt-2">
                            <p className="text-sm font-medium leading-5 text-gray-900 dark:text-white truncate">{currentUser?.fullName}</p>
                            <p className="text-sm leading-5 text-gray-500 dark:text-gray-400 truncate">{currentUser?.email}</p>
                        </div>

                        <hr className='bg-neutral-200 dark:border-neutral-700 my-3'/>
                    

                        <MenuItem>
                            <Link to="/Settings" className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 size-4 lucide-settings"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" /><circle cx={12} cy={12} r={3} /></svg>
                                Settings
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={(e) => handleLogOut(e)} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 size-4 lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} x2={9} y1={12} y2={12} /></svg>
                                Logout
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                            </Link>
                        </MenuItem>


                    </MenuItems>
                </Transition>
            </Menu>


        </div>

    )
}

export default UserDropDown