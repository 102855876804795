import React from 'react';
import Select from 'react-select';

export default function MultiSelect({
  options,
  label,
  handleChange,
  name,
  defaultValue = [],
  disabled = false,
}) {
  const formattedOptions = options.map(option => ({
    value: option.id,
    label: option.title,
  }));

  const defaultSelected = defaultValue.map(value => {
    const option = options.find(opt => opt.id === value);
    return option ? { value: option.id, label: option.title } : null;
  }).filter(Boolean);

  const handleSelectionChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name, value: selectedValues, type: 'select-multiple' } });
  };

  return (
    <div className="w-full">
     
      <Select
        options={formattedOptions}
        isMulti
        isDisabled={disabled}
        defaultValue={defaultSelected}
        onChange={handleSelectionChange}
        placeholder={label}
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable
      />
    </div>
  );
}
