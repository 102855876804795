import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Select, Loading } from '../../components';
import { DeleteModal } from '../../components';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { Calendar, DollarSign, FileText, Timer, CheckCircle, AlertCircle, TrendingUp, TrendingDown, Percent, Plus, X } from 'lucide-react';

function FinancialDashboard() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('revenue');
  const [RevenueModal, setRevenueModal] = useState(false);
  const [ChargeModal, setChargeModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [formData, setFormData] = useState({
    date: null,
    amount: null,
    description: null,
    title: null,
    project_id: null,
    category: null,
    details: [],
  });
  const [loading, setLoading] = useState(false);
  const [revenueList, setRevenueList] = useState([]);
  const [chargesList, setChargesList] = useState([]);
  const [done, setDone] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProject, setSelectedProject] = useState('');
  const [filters, setFilters] = useState({ project: null, start: null, end: null });
  const [analytics, setAnalytics] = useState({
    expenses: 0,
    profit: 0,
    revenue: 0,
    profitPercentage: 0
  });

  const itemsPerPage = 10;

  useEffect(() => {
    getRevenues();
    getCharges();
    getAnalytics();
    getProjects();
  }, [itemId, done]);

  const getRevenues = async (filters) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/revenues/search', filters);
      setRevenueList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getAnalytics = async (filters) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post("/statistiques/get-cards-analytics", filters);
      setAnalytics(data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getCharges = async (filters) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/charges/search', filters);
      setChargesList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getProjects = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/projects/search');
      setProjectsList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleToggleModal = () => {
    if (activeTab === 'revenue') {
      setRevenueModal(!RevenueModal);
    } else {
      setChargeModal(!ChargeModal);
    }
    setFormData({
      date: null,
      amount: null,
      description: null,
      title: null,
      project_id: null,
      category: null,
      details: [],

    });
  };

  const getItemToUpdate = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/${activeTab}s/get/${id}`);
      const responseData = response.data;

      if (responseData) {
        setFormData({
          ...responseData.data,
          project_id: responseData.data.project.id,
          date: responseData.data[`${activeTab}_date`],
          [activeTab]: responseData.data[`${activeTab}_items`] || []
        });

        setItemId(responseData.data.id)

        if (activeTab === 'revenue') {
          setRevenueModal(true);
        } else {
          setChargeModal(true);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const find = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/${activeTab}s/find`, { searchValue });
      if (activeTab === 'revenue') {
        setRevenueList(response.data.data);
      } else {
        setChargesList(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const save = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let response = null;
      const dataToSend = {
        ...formData,
        amount: formData.details.reduce((total, item) => total + parseFloat(item.amount), 0),
      };
      if (itemId) {
        response = await axiosInstance.put(`/${activeTab}s/update/${itemId}`, dataToSend);
      } else {
        response = await axiosInstance.post(`/${activeTab}s/create`, dataToSend);
      }
      const responseData = response.data;
      if (responseData.data) {
        toast.success(responseData.message);
        setItemId(null);
        handleToggleModal();
        setDone(!done);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const reset = () => {
    setSearchValue('');
    setSelectedProject('');
    setFilters({ project: null, start: null, end: null });
    getRevenues();
    getCharges();
    getAnalytics()
  };

  const applyFilter = () => {
    getRevenues(filters);
    getCharges(filters);
    getAnalytics(filters);
  };

  const addItem = () => {
    setFormData(prevData => ({
      ...prevData,
      details: [...prevData.details, { name: '', amount: 0 }]
    }));
  };

  const deleteItem = (index) => {
    setFormData(prevData => ({
      ...prevData,
      details: prevData.details.filter((_, i) => i !== index)
    }));
  };

  const updateItem = (index, field, value) => {
    setFormData(prevData => ({
      ...prevData,
      details: prevData.details.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const filteredItems = activeTab === 'revenue' ? revenueList : chargesList;
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const displayedItems = filteredItems.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="flex flex-col md:w-full w-screen justify-start md:p-5 p-3 min-h-screen bg-neutral-100 dark:bg-neutral-900">
      {loading && <Loading />}

      {/* Analytics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:mb-6 mb-3">
        <AnalyticsCard
          title={t("Total Revenue")}
          value={analytics?.revenue}
          icon={<TrendingUp className="w-6 h-6 text-green-500" />}
          color="bg-white dark:bg-neutral-800"
        />
        <AnalyticsCard
          title={t("Total Charges")}
          value={analytics?.expenses}
          icon={<TrendingDown className="w-6 h-6 text-red-500" />}
          color="bg-white dark:bg-neutral-800"
        />
        <AnalyticsCard
          title={t("Total Profit")}
          value={analytics?.profit.toFixed(2)}
          icon={<DollarSign className="w-6 h-6 text-blue-500" />}
          color="bg-white dark:bg-neutral-800"
        />
        <AnalyticsCard
          title={t("Profit Percentage")}
          value={`${analytics?.profitPercentage.toFixed(2)}%`}
          icon={<Percent className="w-6 h-6 text-purple-500" />}
          color="bg-white dark:bg-neutral-800"
        />
      </div>

      <div className='md:p-6 p-3 bg-white dark:bg-neutral-800 md:mb-6 mb-3 rounded-lg'>
        {/* Filters */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:mb-6 mb-3">
          <Select
            label={t("Filter by Project")}
            options={projectsList}
            name="project_filter"
            handleChange={(e) => setFilters({ ...filters, project: e.target.value })}
            defaultV={selectedProject}
          />
          <Input
            type="date"
            label={t("Start Date")}
            name="start_date"
            handleChange={(e) => setFilters({ ...filters, start: e.target.value })}
            defaultV={filters.start}
          />
          <Input
            type="date"
            label={t("End Date")}
            name="end_date"
            handleChange={(e) => setFilters({ ...filters, end: e.target.value })}
            defaultV={filters.end}
          />
        </div>

        {/* Filter and Reset Buttons */}
        <div className="flex justify-end space-x-4 ">
          <button
            onClick={applyFilter}
            className="px-4 py-2 dark:bg-neutral-100 dark:text-neutral-800 text-white text-sm bg-neutral-800 rounded-lg hover:bg-neutral-500 transition-colors duration-300"
          >
            {t("Apply Filter")}
          </button>
          <button
            onClick={reset}
            className="px-4 py-2 bg-neutral-300 text-neutral-700 rounded-lg hover:bg-neutral-400 transition-colors duration-300"
          >
            {t("Reset")}
          </button>
        </div>
      </div>

      <div className='md:p-6 p-3 bg-white  dark:bg-neutral-800 rounded-lg '>
        <div className="flex gap-2 mb-4">
          <button
            className={`px-4 py-2 bg-neutral-100 rounded-lg dark:bg-neutral-900 font-semibold ${activeTab === 'revenue' ? 'text-neutral-600 dark:text-neutral-400 border-b-2 border-green-600' : 'text-neutral-600 dark:text-neutral-400'}`}
            onClick={() => setActiveTab('revenue')}
          >
            {t("Revenue")}
          </button>
          <button
            className={`px-4 py-2 bg-neutral-100 rounded-lg dark:bg-neutral-900 font-semibold ${activeTab === 'charge' ? 'text-neutral-600 dark:text-neutral-400 border-b-2 border-green-600' : 'text-neutral-600 dark:text-neutral-400'}`}
            onClick={() => setActiveTab('charge')}
          >
            {t("Charges")}
          </button>
        </div>

        <div className="sm:flex sm:items-center md:mb-6 mb-3 sm:justify-between">
          <div className='flex flex-col justify-center items-start'>
            <h1 className="text-2xl font-semibold text-neutral-800 dark:text-white">
              {activeTab === 'revenue' ? t("Revenues") : t("Expenses")}
            </h1>
            <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-300">
              {t(`Here you can manage all your ${activeTab === 'revenue' ? 'revenue' : 'expenses'}.`)}
            </p>
          </div>
          <button onClick={handleToggleModal} className="mt-4 sm:mt-0 flex items-center justify-center px-4 py-2 dark:bg-neutral-100 dark:text-neutral-800 text-white text-sm bg-neutral-800 rounded-lg hover:bg-neutral-500 transition-colors duration-300">
            <Plus className="w-5 h-5 mr-2" />
            <span>{t(`Add ${activeTab === 'revenue' ? 'Revenue' : 'Expense'}`)}</span>
          </button>
        </div>

        {/* Search */}
        <div className="relative mb-5 w-full md:w-1/2">
          <input
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            className="w-full p-3 h-12 px-10 border bg-neutral-100 text-sm border-neutral-200 rounded-md  dark:bg-neutral-900 dark:border-neutral-800"
            placeholder={t("Search for items")}
          />
          <a onClick={(e) => reset(e)} className="absolute top-3 left-2 z-40 flex items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 mr-3 z-40  text-gray-800 dark:text-neutral-100">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </a>
          <button
            onClick={find}
            disabled={!searchValue}
            className="absolute right-2 h-8 top-2 px-4 py-2 dark:bg-neutral-100 dark:text-neutral-800 bg-neutral-800 text-white text-xs font-medium rounded-md hover:bg-neutral-500 focus:outline-none focus:ring-2  focus:ring-offset-2"
          >
            {t("Search")}
          </button>
        </div>

        {/* Table */}
        <div className="overflow-x-auto border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 rounded-lg ">
          <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
            <thead className="bg-neutral-50 dark:bg-neutral-900">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Project")}</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Title")}</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Description")}</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Amount")}</th>
                {activeTab === 'charge' && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Category")}</th>
                )}
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Date")}</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Items")}</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-neutral-200 dark:divide-neutral-700 dark:bg-neutral-800">
              {displayedItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4 text-left whitespace-nowrap">
                    <span className="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-600/10 text-blue-600">
                      {item.project?.title}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">{item.title}</td>
                  <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">{item.description}</td>
                  <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                    <span className={`inline-flex items-center ${item.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                      <DollarSign className="w-4 h-4 mr-1" />
                      {Math.abs(item.amount).toFixed(2)}
                    </span>
                  </td>
                  {activeTab === 'charge' && (
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <span className="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-600/10 text-green-600">
                        {item.category}
                      </span>
                    </td>
                  )}
                  <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1 text-neutral-400" />
                      {activeTab === 'revenue' ? item.revenue_date : item.charge_date}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                    <button
                      onClick={() => {
                        if (!item.details || item.details.length === 0) {
                          toast.warning("No details available for this item.");
                        } else {
                          const modal = document.createElement('div');
                          modal.className = 'fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center';
                          modal.innerHTML = `
                            <div class="bg-white dark:bg-neutral-800 p-6 rounded-lg max-w-md w-full">
                              <h3 class="text-lg font-semibold mb-4 text-neutral-800 dark:text-white">Details</h3>
                              <ul class="space-y-2">
                                ${item.details.map(subItem => `
                                  <li class="text-neutral-600 flex justify-start items-center gap-3 dark:text-neutral-300">
                                   <span>Name : ${subItem.name}</span> 
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right"><path d="M5 12h14"/><path d="m12 5 7 7-7 7"/></svg>
                                    <span>Amount : $${subItem.amount.toFixed(2)}</span>   
                                  </li>
                                `).join('')}
                              </ul>
                              <button class="mt-4 px-4 py-2 bg-neutral-200 dark:bg-neutral-700 text-neutral-800 dark:text-white rounded hover:bg-neutral-300 dark:hover:bg-neutral-600">Close</button>
                            </div>
                          `;
                          document.body.appendChild(modal);
                          modal.querySelector('button').onclick = () => document.body.removeChild(modal);
                        }
                      }}
                      className="px-4 py-2 bg-neutral-200 dark:bg-neutral-700 text-neutral-800 dark:text-white rounded hover:bg-neutral-300 dark:hover:bg-neutral-600 text-sm"
                    >
                      View Details
                    </button>
                  </td>
                  <td className="px-6 space-x-2 text-left py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => getItemToUpdate(item.id)}
                      className="p-2 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 rounded-lg transition-colors duration-200"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setItemId(item.id);
                        setDeleteModal(true);
                      }}
                      className="p-2 bg-red-500/10 hover:bg-red-500/20 text-red-400 rounded-lg transition-colors duration-200"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={({ selected }) => setCurrentPage(selected)}
            containerClassName="flex justify-end mt-8 space-x-2"
            pageClassName="px-3 py-2 rounded-md bg-white text-neutral-700 hover:bg-neutral-50"
            activeClassName="bg-blue-500 text-white"
            previousClassName="px-3 py-2 rounded-md bg-white text-neutral-700 hover:bg-neutral-50"
            nextClassName="px-3 py-2 rounded-md bg-white text-neutral-700 hover:bg-neutral-50"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>
      </div>

      {/* Revenue Modal */}
      <AnimatePresence>
        {RevenueModal && (
          <div
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
            id="modal-id"
          >
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[60%] max-h-[90vh] overflow-y-auto py-4 relative mx-auto mx-2 rounded-xl shadow-lg bg-white dark:bg-neutral-800"
            >
              <div className="flex flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 md:mb-6 mb-3">
                  <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Revenue")}</h2>
                  <a
                    onClick={handleToggleModal}
                    className="flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600"
                  >
                    <X className="w-5 h-5" />
                  </a>
                </div>
                <div className="grid md:grid-cols-2 w-full place-items-center gap-3">
                  <Select
                    label={t("Project")}
                    disabled={false}
                    options={projectsList}
                    name="project_id"
                    handleChange={handleChange}
                    defaultV={formData.project_id || null}
                  />
                  <Input
                    type="text"
                    disabled={false}
                    label={t("Title")}
                    name="title"
                    handleChange={handleChange}
                    defaultV={formData.title || null}
                  />
                  <Input
                    type="date"
                    disabled={false}
                    label={t("Date")}
                    name="revenue_date"
                    handleChange={handleChange}
                    defaultV={formData.revenue_date || null}
                  />
                  <div className="md:col-span-2 grid w-full gap-3">
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder={t("Revenue Description")}
                      rows={4}
                      className="w-full peer py-2 px-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200 dark:focus:ring-neutral-600"
                    />
                  </div>
                </div>

                <div className="w-full mt-4">
                  <h3 className="text-lg text-neutral-800 dark:text-white text-start font-semibold mb-2">{t("Revenue Items")}</h3>
                  {formData.details.map((item, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <input
                        type="text"
                        placeholder='Enter Name ...'
                        value={item.name}
                        onChange={(e) => updateItem(index, 'name', e.target.value)}
                        className="y-2 px-4 block w-full h-12 border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm  
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600"
                      />
                      <input
                        type="number"
                        placeholder={t("Enter Amount ...")}
                        value={item.amount}
                        onChange={(e) => updateItem(index, 'amount', parseFloat(e.target.value))}
                        className="y-2 px-4 block w-full h-12 border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm  
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600"                      />
                      <button
                        onClick={() => deleteItem(index)}
                        className="p-2 bg-red-900/20 text-red-600  rounded-md"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => addItem()}
                    className="mt-2 p-2 bg-green-500 text-xs text-white rounded-lg flex items-center"
                  >
                    <Plus size={16} className="mr-1 " /> {t("Add Revenue Item")}
                  </button>
                </div>

                <div className="md:col-span-3 w-full flex items-center justify-end">
                  <div className="flex items-center md:mt-6 mt-3 gap-x-3">
                    <button
                      onClick={(e) => save(e)}
                      className="flex items-center justify-center w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 dark:bg-neutral-100"
                    >
                      {!loading ? (
                        <>
                          <Plus className="w-5 h-5" />
                          <span>{t("Save revenue")}</span>
                        </>
                      ) : (
                        <div
                          className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {/* Charge Modal */}
      <AnimatePresence>
        {ChargeModal && (
          <div
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
            id="modal-id"
          >
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[60%] max-h-[90vh] overflow-y-auto py-4 relative mx-auto mx-2 rounded-xl shadow-lg bg-white dark:bg-neutral-800"
            >
              <div className="flex flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 md:mb-6 mb-3">
                  <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Expense")}</h2>
                  <a
                    onClick={handleToggleModal}
                    className="flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600"
                  >
                    <X className="w-5 h-5" />
                  </a>
                </div>
                <div className="grid md:grid-cols-2 w-full place-items-center gap-3">
                  <Select
                    label={t("Project")}
                    disabled={false}
                    options={projectsList}
                    name="project_id"
                    handleChange={handleChange}
                    defaultV={formData.project_id || null}
                  />
                  <Input
                    type="text"
                    disabled={false}
                    label={t("Title")}
                    name="title"
                    handleChange={handleChange}
                    defaultV={formData.title || null}
                  />
                  <Input
                    type="date"
                    disabled={false}
                    label={t("Date")}
                    name="charge_date"
                    handleChange={handleChange}
                    defaultV={formData.charge_date || null}
                  />
                  <Input
                    type="text"
                    disabled={false}
                    label={t("Category")}
                    name="category"
                    handleChange={handleChange}
                    defaultV={formData.category || null}
                  />
                  <div className="md:col-span-2 grid w-full gap-3">
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder={t("Charge Description")}
                      rows={4}
                      className="w-full peer py-2 px-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200 dark:focus:ring-neutral-600"
                    />
                  </div>
                </div>

                <div className="w-full mt-4">
                  <h3 className="text-lg text-neutral-800 dark:text-white text-start font-semibold mb-2">{t("Charge Details")}</h3>
                  {formData.details.map((item, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <input
                        type="text"
                        placeholder='Enter Name ...'
                        value={item.name}
                        onChange={(e) => updateItem(index, 'name', e.target.value)}
                        className="y-2 px-4 block w-full h-12 border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm  
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600"
                      />
                      <input
                        type="number"
                        placeholder={t("Enter Amount ...")}
                        value={item.amount}
                        onChange={(e) => updateItem(index, 'amount', parseFloat(e.target.value))}
                        className="y-2 px-4 block w-full h-12 border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm  
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600"                      />
                      <button
                        onClick={() => deleteItem(index)}
                        className="p-2 bg-red-900/20 text-red-600  rounded-md"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => addItem()}
                    className="mt-2 p-2 bg-green-500 text-white text-xs rounded-lg flex items-center"
                  >
                    <Plus size={16} className="mr-1" /> {t("Add Charge Item")}
                  </button>
                </div>

                <div className="md:col-span-3 w-full flex items-center justify-end">
                  <div className="flex items-center md:mt-6 mt-3 gap-x-3">
                    <button
                      onClick={(e) => save(e)}
                      className="flex items-center justify-center w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 dark:bg-neutral-100"
                    >
                      {!loading ? (
                        <>
                          <Plus className="w-5 h-5" />
                          <span>{t("Save expense")}</span>
                        </>
                      ) : (
                        <div
                          className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <DeleteModal
        setDeleteId={setItemId}
        open={deleteModal}
        setOpen={setDeleteModal}
        DeleteId={itemId}
        Model={`${activeTab}s`}
        setDone={setDone}
        setLoding={setLoading}
        done={done}
      />
    </div>
  );
}

function AnalyticsCard({ title, value, icon, color }) {
  return (
    <div className={`p-4 rounded-lg ${color}`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-neutral-500 truncate">{title}</dt>
            <dd>
              <div className="text-lg font-medium text-neutral-900 dark:text-white">{value}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FinancialDashboard;

