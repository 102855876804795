import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutDashboard, FolderKanban, User2Icon, Receipt, Settings } from 'lucide-react'
import useCurrentUser from "../../utils/hooks/useCurrentUser";

import logo from '../../assets/images/logo.webp'

const menuItems = [
    { name: 'Users', icon: User2Icon, path: '/Users' },
    { name: 'Projects', icon: FolderKanban, path: '/projects' },
    { name: 'Financials', icon: Receipt, path: '/FinancialDashboard' },
    { name: 'Settings', icon: Settings, path: '/settings' },
]

const Links = () => {
    const location = useLocation()
    const { currentUser } = useCurrentUser();
    const [menuItems, setMenuItems] = useState([])
    useEffect(() => {
        if (currentUser?.role == 'Admin') {
            setMenuItems(
                [
                    { name: 'Projects', icon: FolderKanban, path: '/projects' },
                    { name: 'Financials', icon: Receipt, path: '/FinancialDashboard' },
                    { name: 'Settings', icon: Settings, path: '/settings' },
                ]

            )
        } else {
            setMenuItems(
                [
                    { name: 'Users', icon: User2Icon, path: '/Users' },
                    { name: 'Projects', icon: FolderKanban, path: '/projects' },
                    { name: 'Financials', icon: Receipt, path: '/FinancialDashboard' },
                    { name: 'Settings', icon: Settings, path: '/settings' },
                ]

            )
        }
    }, [])

    return (
        <div className='md:flex hidden justify-start w-full items-center'>

            <div className={`relative flex w-full justify-start items-center h-20`}>



                <ul className=" flex items-center justify-start gap-2 px-3">
                    {menuItems.map((item) => (
                        <li key={item.name}>
                            <Link
                                to={item.path}
                                className={`flex items-center space-x-3 px-3 py-2 rounded-lg transition-colors duration-200
                    ${location.pathname === item.path
                                        ? 'bg-blue-100 text-blue-600 dark:bg-blue-900/20 dark:text-blue-300'
                                        : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-700'
                                    }`}
                            >
                                <item.icon size={22} />
                                <span className="font-medium text-sm">{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>





        </div>
    )
}

export default Links

