import { Routes, Route } from 'react-router-dom';
import { Login, NotFoundPage, Settings, Projects, FinancialDashboard, Users } from '../pages';
import { MainLayout } from '../layout';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import useCurrentUser from '../utils/hooks/useCurrentUser';

const Routers = () => {
  const { currentUser } = useCurrentUser();

  return (
    <div>
      <Routes>


        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/FinancialDashboard"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FinancialDashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/projects"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Projects />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {
          currentUser?.role === 'SuperAdmin' &&
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Users />
                </MainLayout>
              </ProtectedRoute>
            }
          />
        }

        <Route path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
